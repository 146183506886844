import React from 'react'

export default function Hero() {
    return (
        <section>
            <div className="container mx-auto space-y-4 pt-8">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center">
                    <span className="bg-shape-white-success">Bring out the best</span><br />
                    in your team.
                </h1>
                <p className="mx-auto max-w-2xl text-sm lg:text-lg md:text-base text-center">
                    With Handdy’s wide range of features, always know how employees use their time while working, app and website usage, and increase overall productivity
                </p>
            </div>
        </section >
    )
}
