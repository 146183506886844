import * as React from "react"
import { useLocation } from '@reach/router'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/PageFeatures/Hero"
import FutureCard from "../components/PageFeatures/FutureCard"
import Contact from "../components/PageFeatures/Contact"


const Index = () => {
    const location = useLocation()
    return (
        <Layout>
            <SEO title="Features" />
            <div>
                <div className="features-nav bg-white sticky top-17">
                    <div className="container mx-auto  ">
                        <nav className="flex w-full justify-between text-sm font-bold py-4 text-center">
                            <a className={location.hash == "#employee-monitoring" || !location.hash ? "features-nav-active" : null} href="#employee-monitoring">EMPLOYEE MONITORING</a>
                            <a className={location.hash == "#employee-time" ? "features-nav-active" : null} href="#employee-time">EMPLOYEE TIME AND ATTENDANCE</a>
                            <a className={location.hash == "#employee-productivity" ? "features-nav-active" : null} href="#employee-productivity">EMPLOYEE PRODUCTIVITY ANALYTICS</a>
                        </nav>
                    </div>
                </div>
                <Hero />
                <FutureCard />
            </div>
            {/* <Monitoring /> */}
            <Contact />
        </Layout>
    )
}

export default Index
