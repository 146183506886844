import React, { useState } from 'react'

export default function Contact() {

    const [parsedDate, setParsedDate] = useState(null)

    return (
        <section className="bg-white-100 lg:py-17 md:py-12 sm:py-8 py-4">
            <div className="container mx-auto">
                <div className="grid lg:grid-cols-2 md:grid-cols-2">
                    <div className="max-w-lg">
                        <h6 className="text-body-muted text-lg font-semibold">CONTACT US</h6>
                        <h2 className="text-heading-dark text-5.5xl font-bold">Book a free demo</h2>
                        <p className="text-lg">Contact our team and schedule a free demo to see clearly the features of Handdy and understand how we can help you to increase your team’s productivity.</p>
                        <div className="flex space-x-4 lg:mt-12 md:mt-8 mt-4">
                            <a className="py-3 px-5 rounded-xl flex flex-col bg-danger-dark text-white" href="tel:+1 412 267 1529">
                                <span>Call us (USA)</span>
                                <span>+1 412 267 1529</span>
                            </a>
                            <a className="py-3 px-5 flex flex-col rounded-xl bg-danger-dark text-white" href="tel:+91 93459 24122">
                                <span>Call us (India)</span>
                                <span>+91 93459 24122</span>
                            </a>
                        </div>
                        <img className="lg:mt-16 md:mt-12 mt-8" src="/companies/companies.png"></img>
                    </div>
                    <form className="shadow-outer p-2 lg:p-8 md:p-4 rounded-2xl bg-white grid gap-5 content-start">
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
                            <div className="flex space-y-2 flex-col">
                                <label className="text-sm font-semibold text-heading-dark">Name<span className="text-danger-dark">*</span></label>
                                <input required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Enter your name" type="text"></input>
                            </div>
                            <div className="flex space-y-2 flex-col">
                                <label className="text-sm font-semibold text-heading-dark">Work email<span className="text-danger-dark">*</span></label>
                                <input required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Enter your work email" type="text"></input>
                            </div>
                        </div>
                        <div className="flex space-y-2 flex-col relative">
                            <label className="text-sm font-semibold text-heading-dark">Select date & time<span className="text-danger-dark">*</span></label>
                            <input onChange={(e) => {
                                const dt = new Date(e.target.value);
                                const locale = navigator.languages != undefined ? navigator.languages[0] : navigator.language;
                                const fullMonth = dt.toLocaleDateString(locale, { month: 'long' });
                                const day = dt.toLocaleDateString(locale, { day: 'numeric' });
                                const weekDay = dt.toLocaleDateString(locale, { weekday: 'long' });
                                const hour = dt.toLocaleDateString(locale, {
                                    hour12: true,
                                    hour: 'numeric',
                                });
                                setParsedDate(`${day} ${fullMonth} ${weekDay} - ${hour.substr(hour.length - 4)}`)
                            }
                            } required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Enter your name" type="datetime-local"></input>
                            <p className="absolute bottom-5 left-5">{parsedDate}</p>
                        </div>
                        <div className="flex space-y-2 flex-col">
                            <label className="text-sm font-semibold text-heading-dark">Message</label>
                            <textarea rows="4" className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Anything you want to add?" type="text"></textarea>
                        </div>
                        <button type="submit" className="font-medium focus:outline-none text-white bg-primary text-sm leading-3 py-5 px-6 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg">Book your free demo now</button>
                    </form>
                </div>
            </div>
        </section>
    )
}
