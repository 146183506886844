import React from 'react'
import CheckIcon from '../../../styles/icons/check'

export default function FutureCard() {
    return (
        <section className="grid lg:gap-16 md:gap-12 sm:gap-8 gap-4 lg:pb-20 md:pb-16 pb-8 px-0  lg:pt-20 md:pt-16 pt-8 ">
            <div id="employee-monitoring" className="container mx-auto rounded-3xl bg-primary-success space-y-4 lg:space-y-8 lg:py-40 md:py-32 sm:py-20 py-7 lg:px-24 md:px-16 sm:px-12 px-4">
                <div className="grid gap-4 lg:gap-14 md:gap-14 lg:grid-cols-2 md:grid-cols-2 items-center">
                    <div className="max-w-xs">
                        <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 mb-6">
                            Employee Monitoring
                         </h1>
                        <img src="/graphics-9.svg"></img>
                    </div>
                    <ul className="grid lg:gap-7 md:gap-5 gap-4">
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Employee activity recording</h4>
                            <p className="pl-6">
                                You can track employee’s activity on their PC, websites they visit, software programs they use and more. With these, you can easily identify your star performers from the rest.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Get screen shot images</h4>
                            <p className="pl-6">
                                You can track employee’s activity on their PC, websites they visit, software programs they use and more. With these, you can easily identify your star performers from the rest.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Keystroke activity tracking</h4>
                            <p className="pl-6">
                                You can track employee’s activity on their PC, websites they visit, software programs they use and more. With these, you can easily identify your star performers from the rest.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="employee-time" className="container mx-auto rounded-3xl bg-danger-soft space-y-4 lg:space-y-8 lg:py-40 md:py-32 sm:py-20 py-7 lg:px-24 md:px-16 sm:px-12 px-4">
                <div className="grid gap-4 lg:gap-14 md:gap-14 lg:grid-cols-2 md:grid-cols-2 items-center">
                    <ul className="grid lg:gap-7 md:gap-5 gap-4">
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Track employee time spent</h4>
                            <p className="pl-6">
                                Employee time spent on clients, projects, and tasks are automatically tracked and recorded. Tracked hours are and presented to you on a daily, weekly, or monthly basis.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Who is in today and doing what?</h4>
                            <p className="pl-6">
                                Get quick overall picture. Your manager would love to have a snapshot of who is in today and doing what. Getting this powerful information not a struggle anymore.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Month end attendance report</h4>
                            <p className="pl-6">
                                Get attendance reports from multiple sources and put it all together in just one click, which used to be a time consuming and often error prone job.
                            </p>
                        </li>
                    </ul>
                    <div className="max-w-sm lg:justify-self-end">
                        <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 mb-6">
                            Employee Time and Attendance
                         </h1>
                        <img src="/graphics-10.svg"></img>
                    </div>
                </div>
            </div>
            <div id="employee-productivity" className="container mx-auto rounded-3xl bg-primary-success space-y-4 lg:space-y-8 lg:py-40 md:py-32 sm:py-20 py-7 lg:px-24 md:px-16 sm:px-12 px-4">
                <div className="grid gap-4 lg:gap-14 md:gap-14 lg:grid-cols-2 md:grid-cols-2 items-center">
                    <div className="max-w-xs">
                        <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 mb-6">
                            Employee Productivity Analytics
                         </h1>
                        <img src="/graphics-11.svg"></img>
                    </div>
                    <ul className="grid lg:gap-7 md:gap-5 gap-4">
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Identify top performers</h4>
                            <p className="pl-6">
                                Without real evidence, it is easy to overlook good performers. When real time data is presented to you with evidence, you will know who is contributing more.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Employee behaviour at a granular level</h4>
                            <p className="pl-6">
                                Tracking employee behaviour at a granular level is difficult for most companies. With Handdy, you get a complete picture at every level including staff, team, task, and project.
                            </p>
                        </li>
                        <li>
                            <h4 className="relative pl-6 flex items-center text-body-darker mb-2 text-lg font-bold"><CheckIcon className="absolute left-0" isGreen></CheckIcon>Reduce subjectivity out of decisions</h4>
                            <p className="pl-6">
                                Stop taking management decisions based on gut feeling. Now you can eliminate subjectivity, and instead take informed decisions backed by powerful data.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
