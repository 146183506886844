import React from "react";

function Icon({ isGreen = false, ...rest }) {
    if (isGreen) {
        return (
            <svg
                {...rest}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill="#66CB9F"
                    fillRule="evenodd"
                    d="M10.067.87a2.89 2.89 0 00-4.134 0l-.622.638-.89-.011a2.89 2.89 0 00-2.924 2.924l.01.89-.636.622a2.89 2.89 0 000 4.134l.637.622-.011.89a2.89 2.89 0 002.924 2.924l.89-.01.622.636a2.89 2.89 0 004.134 0l.622-.637.89.011a2.89 2.89 0 002.924-2.924l-.01-.89.636-.622a2.89 2.89 0 000-4.134l-.637-.622.011-.89a2.89 2.89 0 00-2.924-2.924l-.89.01-.622-.636zm.287 5.984a.5.5 0 00-.708-.708L7 8.793 5.854 7.646a.5.5 0 10-.708.708l1.5 1.5a.5.5 0 00.708 0l3-3z"
                    clipRule="evenodd"
                ></path>
            </svg>
        );
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#F7936F"
                strokeLinejoin="round"
                strokeWidth="3.334"
                d="M16.302 6.04l-7.954 7.956-5.031-5.033"
            ></path>
        </svg>
    );
}

export default Icon;
